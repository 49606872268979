<template>
  <div class="security">
    <pageTitle :title="'消息配置'"></pageTitle>
    <div class="content">
      <div class="loginInfo">
        <div>
          <span>您可以使用的系统消息接收方式：</span>
        </div>
      </div>
      <div class="account">
        <div class="item">
          <div class="clearfix">
            <div class="fl clearfix titleFl">
              <div class="title fl">{{ $t("phone") }}</div>
              <div class="fl text">
                <el-switch v-model="msgConfig.phone" @change="changeMsgConfig($event, 'phone')" active-text="关闭" inactive-text="开启" active-color="#dcdfe6" inactive-color="#13ce66" :active-value="false" :inactive-value="true"></el-switch>
                您将使用手机号 {{ `${phoneFormat(
                  USER_INFO.mobile,
                  USER_INFO.area_code
                )}` }} 接收短信，可通过<router-link to="/console/account/security">安全设置</router-link>修改。
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="clearfix">
            <div class="fl clearfix titleFl">
              <div class="title fl">{{ $t("email") }}</div>
              <div class="fl text">
                <el-switch v-model="msgConfig.email"  @change="changeMsgConfig($event, 'email')" active-text="关闭" inactive-text="开启" active-color="#dcdfe6" inactive-color="#13ce66" :active-value="false" :inactive-value="true"></el-switch>
                您将使用邮箱 {{ USER_INFO.email | textFormat }} 接收邮件，可通过<router-link
                  to="/console/account/userInfo">个人资料</router-link>修改。
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="clearfix">
            <div class="fl clearfix titleFl">
              <div class="title fl">QQ消息</div>
              <div class="fl text">
                <el-switch v-model="msgConfig.qq"  @change="changeMsgConfig($event, 'qq')" active-text="关闭" inactive-text="开启" active-color="#dcdfe6" inactive-color="#13ce66" :active-value="false" :inactive-value="true"></el-switch>
                您将使用QQ {{ USER_INFO.qq | textFormat }} 接收通知消息，可通过<router-link
                  to="/console/account/userInfo">个人资料</router-link>修改。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <div class="changeEmailDialog">
      <el-dialog :visible.sync="changeEmailDialog" :title="$t('changeEmail')" width="410px" :close-on-click-modal="false">
        <el-form class="onlyBorderBottomForm" :model="emailForm" ref="emailForm" v-show="showEmailForm"
          :rules="emailRules">
          <el-form-item prop="email">
            <el-input type="text" v-model="emailForm.email" :placeholder="$t('enterEmail')"></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" v-show="showEmailForm">
          <el-button type="default" size="small" @click="changeEmailDialog = false">{{ $t("cancel") }}</el-button>
          <el-button type="primary" size="small" @click="getEmail" :loading="emailLoading">{{ $t("getEmail")
          }}</el-button>
        </div>
        <div class="hasSendTip commonTips tc" v-show="!showEmailForm">
          <p class="mainTip">{{ $t("emailSendTip") }}</p>
          <p class="email">
            <EmailLogin :email="emailForm.email"></EmailLogin>
          </p>
          <p class="subTip">{{ $t("emailSendTip2") }}</p>
        </div>
      </el-dialog>
    </div>
    <!-- 电话 -->
    <div class="changePhomeDialog">
      <el-dialog :visible.sync="changePhoneDialog" :title="$t('changePhone')" width="410px" :close-on-click-modal="false">
        <el-form ref="phoneForm" :model="phoneForm" class="onlyBorderBottomForm" :rules="phoneRules">
          <el-form-item prop="phone">
            <el-input v-model="phoneForm.phone" type="number" class="input-with-select">
              <el-select v-model="phoneForm.areaCode" slot="prepend" filterable :filter-method="areaCodeFilter"
                popper-class="registerAreaCodeList">
                <el-option v-for="(item, index) in areaCodeList" :key="item.area_code + index + ''"
                  :value="item.area_code" :title="item.country | areacodeTitleFormat(LOCALE)">
                  <p class="area_code">{{ item.area_code }}</p>
                  <p class="country textOverflow">
                    {{ item.country | areacodeAddressFormat(LOCALE) }}
                  </p>
                </el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item prop="code" v-if="LOCALE == 'zh'">
            <el-input v-model="phoneForm.code" type="number">
              <el-button slot="append" class="getCodeBtn" @click="getCode" :disabled="codeBtnDisabled">
                {{ codeText }}
                <span v-if="parseInt(codeText) == codeText">&nbsp;s</span>
              </el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button type="default" size="small" @click="changePhoneDialog = false">{{ $t("cancel") }}</el-button>
          <el-button type="primary" size="small" @click="changePhone" :loading="phoneLoading">{{ $t("save") }}</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 微信 -->
    <div class="changeweChatDialog">
      <el-dialog :visible.sync="changeweChatDialog" :title="$t('changeWechat')" width="360px"
        :close-on-click-modal="false">
        <div id="login_container"></div>
        <div class="jscode" :class="LOCALE">{{ $t("jsCode") }}</div>
      </el-dialog>
    </div>
    <!-- 密码 -->
    <div class="changePasswordDialog">
      <el-dialog :visible.sync="changePasswordDialog" :title="$t('updatePassword')" width="410px"
        :close-on-click-modal="false">
        <el-form class="onlyBorderBottomForm" ref="passwordForm" :rules="passwordRules" :model="passwordForm">
          <el-form-item prop="original_password">
            <el-input v-if="USER_INFO.is_password" :show-password="true" type="password"
              v-model="passwordForm.original_password" :placeholder="$t('enterOriginPassword')"></el-input>
          </el-form-item>
          <el-form-item prop="new_password">
            <el-input :show-password="true" type="password" v-model="passwordForm.new_password"
              :placeholder="$t('enterNewPassword')"></el-input>
          </el-form-item>
          <el-form-item prop="confirm_password">
            <el-input :show-password="true" type="password" v-model="passwordForm.confirm_password"
              :placeholder="$t('passwordAgain')"></el-input>
            <div class="custom_error_tip" v-show="passwordCetShow">
              {{ $t("wrongPassword") }}
            </div>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button type="default" size="small" @click="changePasswordDialog = false">{{ $t("cancel") }}</el-button>
          <el-button type="primary" size="small" @click="updataPassword" :loading="passworLoading">{{ $t("save")
          }}</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 删除账号 -->
    <div class="deleteAccountDialog">
      <el-dialog :visible.sync="deleteAccountDialog" :title="$t('deleteAccount')" width="550px"
        :close-on-click-modal="false">
        <div class="deleteaccount_tips">
          <h2 class="main">
            {{ $t("sendApplyTip$") }}
            <a href="mailto:info@ailaworld.com" class="mainColor">info@ailaworld.com</a>
          </h2>
          <p class="sub">{{ $t("sendApplySubTip") }}</p>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import pageTitle from "~bac/components/common/pageTitle";
import { phoneFormat } from "~/basePlugins/filters";
import EmailLogin from "~/baseComponents/EmailLogin";
import { areaCodeList } from "~/baseUtils/AddressConfig";
import { _decodeString } from "~/basePlugins/base64";
import baseConfig from "~/baseConfig/index.js";

import {
  validateTelephone,
  validateEmailOrTelephone,
  validateEmail,
  validatePassWord,
} from "~/baseUtils/validate";
export default {
  name: 'mgsConfig',
  components: { pageTitle, EmailLogin },
  data() {
    return {
      phoneFormat,
      changePasswordDialog: false,
      deleteAccountDialog: false,
      changeEmailDialog: false,
      changePhoneDialog: false,
      changeweChatDialog: false,
      showEmailForm: true,
      emailLoading: false,
      phoneLoading: false,
      passworLoading: false,
      passwordCetShow: false,
      areaCodeList,
      codeText: this.$t("getvCode"),
      codeBtnDisabled: false,
      areaCodeListCopy: [].concat(areaCodeList),
      phoneForm: {
        code: "",
        areaCode: "+86",
        phone: "",
      },
      accountId: "",
      passwordForm: {
        original_password: "",
        new_password: "",
        confirm_password: "",
      },
      setPasswordForm: {
        new_password: "",
        confirm_password: "",
      },
      emailForm: {
        email: "",
      },
      msgConfig:{
        phone: true,
        email: true,
        qq: true,
      },
      passwordRules: {
        original_password: {
          validator: (rule, value, callback) => {
            if (this.USER_INFO.is_password && value == "") {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: "blur",
          required: true,
        },
        new_password: {
          validator: (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validatePassWord(value)) {
              callback(new Error(this.$t("wrongPasswordFormat")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
        confirm_password: {
          validator: (rule, value, callback) => {
            if (this.passwordForm.new_password == "") {
              callback();
            } else if (this.passwordForm.new_password != "" && value == "") {
              callback(new Error(this.$t(" ")));
            } else if (
              this.passwordForm.new_password != "" &&
              value !== this.passwordForm.new_password
            ) {
              this.passwordCetShow = false;
              callback(new Error(this.$t("passwordMatch")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      emailRules: {
        email: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (!validateEmail(value)) {
              callback(new Error(this.$t("wrongEmailFormat")));
            } else {
              try {
                let result = await this.$store.dispatch(
                  "baseStore/baseSign_checkIdentifier",
                  { identifier: value, type: 1 }
                );
                if (result.success) {
                  callback();
                } else {
                  if (result.errorcode == 1000) {
                    callback(new Error(this.$t("emailAladyTip")));
                  }
                  callback(new Error(" "));
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
              }
            }
          },
          trigger: "blur",
        },
      },
      phoneRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback(new Error(" "));
            } else if (
              this.phoneForm.areaCode == "+86" &&
              !validateTelephone(value)
            ) {
              callback(new Error(this.$t("wrongTelephoneFormat")));
            } else {
              try {
                let params = { type: 2 }
                if (this.PJID === 'YMP') {
                  params.identifier = value
                } else {
                  params.identifier = `${this.phoneForm.areaCode}${value}`
                }
                let result = await this.$store.dispatch(
                  "baseStore/baseSign_checkIdentifier", params
                );
                if (result.success) {
                  callback();
                } else {
                  if (result.errorcode == 1000) {
                    callback(new Error(this.$t("phoneAladyTip")));
                  }
                  callback(new Error(" "));
                }
              } catch (e) {
                callback(new Error(" "));
                this.$seTip();
              }
            }
          },
          trigger: "blur",
        },
        code: {
          validator: (rule, value, callback) => {
            if (this.phoneForm.phone == "") {
              callback();
            } else if (this.phoneForm.phone != "" && value == "") {
              callback(new Error(this.$t(" ")));
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  mounted() {
    if (this.PJID == 'YMP') {
      this.$log({
        type: 2,
        topic: "ymp.workbench",
        id: "ymp.workbench.safe",
      });
      this.getUSerMessage();
    }
    if (this.$route.query.code) {
      this.weChatChange(this.$route.query.code);
    }
    this.changeEmail();
    this.emailForm.email = this.USER_INFO.email;
    this.phoneForm.areaCode = this.USER_INFO.area_code || "+86";
    this.phoneForm.phone = this.USER_INFO.mobile;
    this.accountId = `${this.$moment(this.USER_INFO.created_time * 1000).format(
      "YYYYMMDD"
    )}${this.USER_INFO.id}`;
  },
  methods: {
    async changeMsgConfig(vaild, type){
      let params = {
        user_type : 0,
        user_id: this.USER_ID,
        mobile: this.USER_INFO.mobile || '',
        mail_flag: this.msgConfig.email ? 1:0 ,
        qq_flag: this.msgConfig.qq ? 1:0,
        sms_flag:  this.msgConfig.phone ? 1:0,
        id: this.msgConfig.id ? this.msgConfig.id: ''
      }
      let res = await this.$store.dispatch("API_freight/setUSerMessage", params);
      this.loading = false;
      if (res.success) {
        this.getUSerMessage();
        this.$message.success('操作成功')
      }
    },
    async getUSerMessage() {
      let params = {
        user_id: this.USER_ID,
        user_type: 0, // 用户类型，0-前台用户,0-后台用户 
      }
      let res = await this.$store.dispatch("API_freight/getUSerMessage", params);
      this.loading = false;
      if (res.success && res.data) {
        this.msgConfig = {
          phone: res.data.sms_flag == 1 ? true: false,
          email: res.data.mail_flag == 1? true: false,
          qq: res.data.qq_flag == 1? true: false,
          id: res.data.id,
        };
      }
    },
    getEmail() {
      this.$refs.emailForm.validate(async (vaild) => {
        if (vaild) {
          this.emailLoading = true;
          try {
            let result = await this.$store.dispatch(
              "baseConsole/user_getCheckEmail",
              {
                user_id: this.USER_INFO.id,
                email: this.emailForm.email,
                domain_name_source: this.LOCALE == "en" ? 2 : 1,
              }
            );
            if (result.success) {
              this.showEmailForm = false;
            } else {
              this.$seTip();
            }
            this.emailLoading = false;
          } catch (e) {
            this.emailLoading = false;
            this.$seTip();
          }
        }
      });
    },
    async changeEmail() {
      if (!this.$route.query.parameter) {
        return;
      }
      let params;
      params = _decodeString(this.$route.query.parameter);
      console.log(params);
      if (params.code && params.email) {
        try {
          let result = await this.$store.dispatch("baseStore/baseSign_Verify", {
            type: params.type,
            email: params.email,
            code: params.code,
            source: params.source,
            user_id: params.user_id,
          });
          if (result.success) {
            try {
              let bindResult = await this.$store.dispatch(
                "baseConsole/user_bingIndentity",
                {
                  user_id: params.user_id,
                  identifier: params.email,
                  type: 1,
                }
              );
              if (bindResult.success) {
                this.$message.success(this.$t("changeSuccess"));
              }
            } catch (e) {
              console.log(e);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
    changePhone() {
      if (this.PJID == 'YMP') {
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.safe.mobile",
          content: JSON.stringify({
            id: this.USER_INFO.id || ''
          }),
        });
      }
      this.$refs.phoneForm.validate(async (valid) => {
        if (valid) {
          if (this.LOCALE == "en") {
            this.phoneSuccess();
          } else {
            try {
              this.phoneLoading = true;
              let result = await this.$store.dispatch(
                "baseStore/checkVerifyCode",
                {
                  area_code: this.phoneForm.areaCode.replace("+", ""),
                  mobile: `${this.phoneForm.phone}`,
                  ctype: 3,
                  code: this.phoneForm.code,
                }
              );
              if (result.success) {
                if (result.data) {
                  this.phoneSuccess();
                } else {
                  this.phoneLoading = false;
                  this.$message.warning(this.$t("errorVerifyCode"));
                }
              } else {
                this.$seTip();
              }
            } catch (e) {
              this.phoneLoading = false;
              this.$seTip();
            }
          }
        }
      });
    },
    async phoneSuccess() {
      try {
        let bindResult = await this.$store.dispatch(
          "baseConsole/user_bingIndentity",
          {
            user_id: this.USER_INFO.id,
            identifier: `${this.phoneForm.areaCode}|${this.phoneForm.phone}`,
            type: 2,
          }
        );
        this.phoneLoading = false;
        if (bindResult.success) {
          this.changePhoneDialog = false;
          this.$message.success(this.$t("changeSuccess"));
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.phoneLoading = false;
        this.$seTip();
      }
    },
    async getCode() {
      if (this.phoneForm.phone == "") {
        this.$message.warning(this.$t("enterTelephone"));
        return;
      }
      try {
        let result = await this.$store.dispatch("baseStore/getVerifyCode", {
          area_code: this.phoneForm.areaCode.replace("+", ""),
          mobile: `${this.phoneForm.phone}`,
          ctype: 3,
        });
        if (result.success) {
          this.$message.success(this.$t("vcodeSendSuccess"));
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.codeText = TIME_COUNT;
            this.codeBtnDisabled = true;
            this.timer = setInterval(() => {
              if (this.codeText > 0 && this.codeText <= TIME_COUNT) {
                this.codeText--;
              } else {
                this.codeText = this.$t("getvCode");
                clearInterval(this.timer);
                this.timer = null;
                this.codeBtnDisabled = false;
              }
            }, 1000);
          }
        } else {
          this.$seTip();
        }
      } catch (e) {
        this.$seTip();
      }
    },
    async weChatChange(code) {
      let isNew;
      if (this.USER_INFO.wechat_verified == 1) {
        isNew = 0;
      } else {
        isNew = 1;
      }
      try {
        let params = {
          user_id: this.USER_INFO.id,
          code: code,
          is_new: isNew,
        };
        params.source = this.PJSource;
        let result = await this.$store.dispatch(
          "register/wechatBindCenter",
          params
        );
        if (result.success) {
          this.$message.success(this.$t("boundSuccess"));
          let data = await this.$store.dispatch(
            "baseStore/baseSign_getUserInfo",
            {
              user_id: this.USER_INFO.id,
            }
          );
        } else if (result.errorcode == 1023) {
          if (this.LOCALE == "en") {
            this.$message.warning(result.message);
          } else {
            this.$message.warning(this.$t("wechatBindCenter"));
          }
        }
      } catch (e) { }
    },
    wechatBinding() {
      // this.changeweChatDialog = true;
      let redirectUrl = encodeURI(
        window.location.origin + "/console/account/security"
      );
      let url = ""
      if (this.PJSource == 21) {
        url = `https://wx.ailaworld.com?appIdType=AXP&redirect_url=${redirectUrl}`;
      } else {
        url = `https://wx.ailaworld.com?appIdType=EXP&redirect_url=${redirectUrl}`;
      }
      window.location.href = url;
    },
    updataPassword() {
      if (this.PJID == 'YMP') {
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.safe.password",
          content: JSON.stringify({
            id: this.USER_INFO.id || ''
          }),
        });
      }
      this.$refs.passwordForm.validate(async (vaild) => {
        if (vaild) {
          this.passworLoading = true;
          let params = {
            user_id: this.USER_INFO.id
          };
          if (this.PJID === 'YMP') {
            params.password = this.passwordForm.new_password
          }
          if (this.PJID !== 'YMP') {
            params.new_password = this.passwordForm.new_password
          }
          if (this.passwordForm.original_password) {
            params.original_password = this.passwordForm.original_password;
            if (this.PJID === 'YMP') {
              params.booking = 1
            }
          }
          try {
            let result = await this.$store.dispatch(
              "baseConsole/user_updataPassword",
              params
            );
            if (result.success) {
              this.$message.success(this.$t("changePasswordSuccess"));
              this.$nextTick(() => {
                this.$store.commit("baseStore/CLEAR_USER_INFO");
                this.$router.push("/sign");
              });
            } else {
              if (result.errorcode == 1010) {
                this.passwordCetShow = true;
              } else {
                this.$seTip();
              }
            }
            this.passworLoading = false;
          } catch (e) {
            this.$seTip();
            this.passworLoading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.security {
  div {
    color: #808080;
  }

  .content {
    padding: 0 12px 12px 12px;

    .loginInfo {
      padding: 10px 0;
      border-top: 1px solid #ececec;
      border-bottom: 1px solid #ececec;

      &>div {
        margin: 10px 0;
      }
    }

    .account {
      .item {
        border-bottom: 1px dashed #ececec;
        padding: 30px 50px 30px 30px;

        .title {
          font-weight: bold;
          width: 120px;
          display: inline-block;
        }

        .titleFl {
          width: 87%;

          .text {
            width: 85%;
            .el-switch{
              width: 200px;
              color: #dcdfe6;
            }

            a {
              color: #027FFF;
            }
          }
        }

        .noSet {
          color: #ff9900;

          i {
            font-size: 16px;
            margin: 0 5px;
            position: relative;
            top: 1px;
          }
        }

        .isSet {
          color: #009900;

          i {
            font-size: 16px;
            margin: 0 5px;
            position: relative;
            top: 1px;
          }
        }

        .line {
          margin: 0 5px;
          color: #cccccc;
        }

        .jscode {
          position: absolute;
          bottom: 80px;
          left: 40%;
          font-size: 16px;
          transform: translate(0, -50%);
        }

        .en {
          left: 32% !important;
        }

        /deep/.el-button {
          padding: 0;
        }
      }
    }
  }

  .el-form-item {
    margin-bottom: 16px;
  }

  .deleteaccount_tips {
    text-align: center;

    .main {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .sub {
      color: #6f6f6f;
      font-size: 12px;
    }
  }
}

.custom_error_tip {
  position: absolute;
  color: #f56c6c !important;
  font-size: 12px;
  margin-top: -5px;
}
</style>
